.social-media-div {
    font-size: 2em;
}

.icon-button {
    margin-bottom: 10px;
}

.icon-button i {
    color: white;
    border-radius: 2.6rem;
    cursor: pointer;
    display: inline-block;
    font-size: 1.3rem;
    height: 2.6rem;
    line-height: 2.6rem;
    margin: 0 10px;
    position: relative;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 2.6rem;
    transition: 0.2s ease-in;
}

.linkedin i {
    background-color: #0e76a8;
}

.github i {
    background-color: #333;
}

.gitlab i {
    background-color: #fca326;
}

.google i {
    background-color: #ea4335;
}

.instagram i {
    background-color: #fd1d1d;
}

.twitter i {
    background-color: #1da1f2;
}

.medium i {
    background-color: #000;
}

.dev-community i {
    background-color: #000;
}

.twitter i:hover,
.google i:hover,
.gitlab i:hover,
.github i:hover,
.linkedin i:hover,
.instagram i:hover,
.dev-community i:hover,
.medium i:hover {
    transform: scale(1.5);
    z-index: 10;
    transition: transform 0.2s ease-in;
}

@media (max-width: 768px) {
    .social-media-div {
        text-align: center;
    }
}
