@font-face {
    font-family: 'Agustina Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Agustina Regular'), url('./assets/fonts/Agustina.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url('./assets/fonts/Montserrat-Regular.ttf') format('woff');
    font-display: swap;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font: 19px / 23px Montserrat, "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 1380px) {
    html,
    body {
        font-size: 16px;
        line-height: normal;
    }
}
