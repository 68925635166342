.header {
    background-color: #fff;
    max-width: 100%;
    padding: 15px 20px;
    margin: 0px auto;
}

.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
}

.grey-color {
    color: #868e96;
}

.header li a {
    display: block;
    padding: 20px 20px;
    color: black;
    text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
    background-color: #f4f4f4;
}

.header .logo {
    display: block;
    flex: none;
    float: left;
    font-size: 1.5em;
    text-decoration: none;
    margin-top: 10px;
    line-height: normal;
}

.header .logo-name {
    font-family: "Agustina Regular";
    font-weight: bold;
    font-variant-ligatures: no-common-ligatures;
    -webkit-font-variant-ligatures: no-common-ligatures;
    padding: 0 10px;
}

.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
}

.header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
}

.header .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: #333;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
}

.header .menu-icon .navicon:before {
    top: 5px;
}

.header .menu-icon .navicon:after {
    top: -5px;
}

.header .menu-btn {
    display: none;
}

.header .menu-btn:checked ~ .menu {
    max-height: 370px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
}

@media (min-width: 48em) {
    .header li {
        float: left;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header .menu {
        max-height: none;
    }

    .header .menu-icon {
        display: none;
    }
}

@media (max-width: 960px) and (min-width: 768px) {
    .header {
        font-size: 12px;
    }
}
